<template>
  <div>
<!-- 
    <base-header 
      style="background-size: 100% 500px;background-image:linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.2)), url('img/industry.jpg');" 
      class="pb-6 pb-8 pt-5 pt-md-8">
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>
 -->
     <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      :style="(this.headers && this.headers.listadoEquipos)? {
        maxHeight: '150px',
        backgroundImage: this.headers.listadoEquipos.backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: this.headers.listadoEquipos.backgroundPosition
      } : { maxHeight: '150px' }"
    >
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>

    <Dialog header="Crear Nuevo Equipo"
      v-model:visible="showModalCrearEquipo"
      :breakpoints="{'960px': '90vw', '640px': '100vw'}" :style="{width: '75vw'}">
      <ModalNuevoEquipo />
    </Dialog>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <!-- Card stats -->
          <div class="row">
            <div class="col-xl-3 col-lg-6">
              <stats-card
                title="Total equipos"
                type="gradient-green"
                v-bind:sub-title="totalitems.toString()"
                icon="ni ni-active-40"
                class="mb-4 mb-xl-0"
              >
                <template v-slot:footer>
                  <span class="text-nowrap">&nbsp;</span>
                  <!-- <span class="text-nowrap">Elementos en lista</span> -->
                </template>
              </stats-card>
            </div>
            <div v-if="(site == 'BHP')" class="col-xl-3 col-lg-6">
              <stats-card
                title="Equipos en garantia"
                type="gradient-orange"
                v-bind:sub-title="totalitems"
                icon="ni ni-chart-pie-35"
                class="mb-4 mb-xl-0"
              >
                <template v-slot:footer>
                  <span class="text-nowrap">&nbsp;</span>
                </template>
              </stats-card>
            </div>
            <div v-if="(site == 'BHP')" class="col-xl-3 col-lg-6">
              <stats-card
                title="Equipos en falla"
                type="gradient-red"
                v-bind:sub-title="totalEnFalla"
                icon="ni ni-chart-bar-32"
                class="mb-4 mb-xl-0"
              >
                <template v-slot:footer>
                  <span class="text-nowrap">&nbsp;</span>
                </template>
              </stats-card>
            </div>
            <div v-if="(site == 'BHP')" class="col-xl-3 col-lg-6">
              <stats-card
                title="En garantia próximos a vencer"
                type="gradient-info"
                sub-title="0"
                icon="ni ni-money-coins"
                class="mb-4 mb-xl-0"
              >
                <!-- <template v-slot:footer>
                  <span class="text-nowrap">Elementos en lista</span>
                </template> -->
              </stats-card>
            </div>
            <!-- <div class="col-xl-3 col-lg-6">
              <stats-card
                title="Duracion promedio garantías"
                type="gradient-info"
                sub-title="18.0 meses"
                icon="ni ni-chart-bar-32"
                class="mb-4 mb-xl-0"
              >
              </stats-card>
            </div> -->
          </div>

          <DataTable
            :value="fullTable"
            :paginator="true"
            class="p-datatable-customers"
            :rows="10"
            :rowsPerPageOptions="[10, 25, 50]"
            v-model:filters="filters"
            filterDisplay="menu"
            :loading="loading"
            :globalFilterFields="[
              'itemN',
              'nombreEquipo',
              'proyecto',
              'descripcion',
              'fabricante',
              'modelo',
              'ubicacion',
              'fechaPES',
              'fechaFinGarantia',
              'categoria',
            ]"

            responsiveLayout="scroll"
          >
            <template #header>
              <div class="p-d-flex p-jc-between p-ai-center">
                <h5 class="p-m-0">Listado de equipos</h5>
                
                <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" placeholder="Buscar" />
                </span>
                <!-- <Button label="Agregar Equipo" icon="pi pi-plus" @click="modalAgregarEquipo()"/> -->
              </div>
            </template>
            <Column field="itemN" header="nº" :sortable="true">
              <template #body="body">
                <base-button
                  v-model="body.data.itemN"
                  size="sm"
                  type="secondary"
                  :id="body.data.key"
                  v-on:click="select($event)"
                >
                  {{ body.data.itemN }}
                </base-button>
              </template>
            </Column>
<!--
            <Column field="POrder" header="Purchase Order"></Column>
            <Column field="Equipment" header="Equipment"></Column>
            <Column field="Supplier" header="Supplier">
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by supplier"
                />
              </template>
            </Column>
            <Column field="PartNumber" header="Part Number"></Column>
            <Column field="SpareType" header="Spare Type"></Column>
-->

            <Column v-if="this.columnsDataTableElements.ID" field="ID" :sortable="true" header="ID"></Column>
            <Column v-if="this.columnsDataTableElements.TAG" field="TAG" :sortable="true" header="TAG"></Column>
            <Column v-if="this.columnsDataTableElements.numeroSerie" field="numeroSerie" header="Número de serie"></Column>
            <Column v-if="this.columnsDataTableElements.nombreEquipo" field="nombreEquipo" :sortable="true" header="Nombre del Equipo"></Column>

            <Column v-if="this.columnsDataTableElements.descripcion" field="descripcion" header="Descripción del Equipo"></Column>

            <Column v-if="this.columnsDataTableElements.contrato" field="contrato" header="Contrato"></Column>
            <Column v-if="this.columnsDataTableElements.idContrato" field="idContrato" header="Identificador contrato"></Column>
            <Column v-if="this.columnsDataTableElements.proyecto" field="proyecto" :sortable="true" header="Proyecto"></Column>

            <Column v-if="this.columnsDataTableElements.fabricante" field="fabricante" header="Fabricante"></Column>
            <Column v-if="this.columnsDataTableElements.proveedor" field="proveedor" header="Proveedor"></Column>
            <Column v-if="this.columnsDataTableElements.modelo" field="modelo" header="Modelo Equipo"></Column>
            <Column v-if="this.columnsDataTableElements.ubicacion" field="ubicacion" :sortable="true" header="Ubicación"></Column>

            <Column v-if="this.columnsDataTableElements.areaResponsable" field="areaResponsable" header="Área Responsable"></Column>
            <Column v-if="this.columnsDataTableElements.comentarios" field="comentarios" header="Comentarios (tension kV)"></Column>
            <Column v-if="this.columnsDataTableElements.anoFabricacion" field="anoFabricacion" header="Año fabricación"></Column>

            <Column v-if="this.columnsDataTableElements.fechaPES" field="fechaPES" :sortable="true" header="Fecha Puesta en Servicio">
              <template #body="body">
                {{ formatDate(body.data.fechaPES, 'DD-MM-YYYY') }}
              </template>
            </Column>

            <Column v-if="this.columnsDataTableElements.mesesGarantia" field="mesesGarantia" header="Meses"></Column>

            <Column v-if="this.columnsDataTableElements.fechaFinGarantia" field="fechaFinGarantia" :sortable="true" header="Fin garantía">
              <template #body="body">
                {{ formatDate(body.data.fechaFinGarantia, 'DD-MM-YYYY') }}
              </template>
            </Column>
            <Column v-if="this.columnsDataTableElements.diasFinGarantia" field="diasFinGarantia" :sortable="true" header="Días garantía">
              <template #body="body">
                <div v-if="body.data.diasFinGarantia < 30" class="text-center">
                  <span style="color: red;">{{ body.data.diasFinGarantia }}</span>
                  <i class="pi pi-exclamation-circle ml-2 mx-1" style="color: red;"/>
                </div>
                <div v-else-if="body.data.diasFinGarantia < 60" class="text-center">
                  <span style="color: darkorange ;">{{ body.data.diasFinGarantia }}</span>
                  <i class="pi pi-exclamation-circle ml-2 mx-1" style="color: darkorange ;"/>
                </div>
                <div v-else-if="body.data.diasFinGarantia < 90" class="text-center">
                  <span style="color: orange">{{ body.data.diasFinGarantia }}</span>
                  <i class="pi pi-exclamation-circle ml-2 mx-1" style="color: orange"/>
                </div>
                <div v-else class="text-center">
                  <span style="color: green;">{{ body.data.diasFinGarantia }}</span>
                  <i class="pi pi-check-circle ml-2 mx-1" style="color: green;"/>
                </div>
              </template>
            </Column>            

            <Column v-if="this.columnsDataTableElements.localizacion" field="localizacion" :sortable="true" header="Ubicación"></Column>
            <Column v-if="this.columnsDataTableElements.timestampLastMessage" field="globalstarData" :sortable="true" header="Ultima conexión">
              <template #body="body">
                <div v-if="body.data.globalstarData">
                  <div v-if="(body.data.globalstarData.timestampLastMessage == 0)" class="text-center">
                    <span style="color: skyblue;">No incializado</span>
                    <!-- <i class="pi pi-check-circle ml-2 mx-1" style="color: skyblue;"/> -->
                  </div>
                  <div v-else-if="(Date.now() - body.data.globalstarData.timestampLastMessage) > this.timeAlerts.error">
                    <span style="color: red;">{{ formatDate(body.data.globalstarData.timestampLastMessage, 'DD-MM-YY HH:mm') }}</span>
                    <i class="pi pi-exclamation-circle ml-2 mx-1" style="color: red;"/>
                  </div>
                  <div v-else-if="(Date.now() - body.data.globalstarData.timestampLastMessage) > this.timeAlerts.warn">
                    <span style="color: orange ;">{{ formatDate(body.data.globalstarData.timestampLastMessage, 'DD-MM-YY HH:mm') }}</span>
                    <i class="pi pi-exclamation-circle ml-2 mx-1" style="color: orange ;"/>
                  </div>
                  <div v-else-if="(Date.now() - body.data.globalstarData.timestampLastMessage) < this.timeAlerts.success">
                    <span style="color: green">{{ formatDate(body.data.globalstarData.timestampLastMessage, 'DD-MM-YY HH:mm') }}</span>
                    <i class="pi pi-check-circle ml-2 mx-1" style="color: green;"/>
                  </div>
                  <div v-else>
                    <span>{{ formatDate(body.data.globalstarData.timestampLastMessage, 'DD-MM-YY HH:mm') }}</span>
                  </div>
                </div>
                <div v-else class="text-center">
                  <span>-</span>
                </div>

              </template>
            </Column>            

            <Column v-if="this.columnsDataTableElements.categoria" field="categoria" :sortable="true" header="Categoría Equipo"></Column>
            <Column v-if="this.columnsDataTableElements.falla" field="falla" :sortable="true" header="Estado">
              <template #body="body">
                <div v-if="body.data.falla" class="text-center">
                  <i class="pi pi-exclamation-circle ml-2 mx-1" style="color: red;"/>
                  <span style="color: red;">Falla</span>
                </div>
                <div v-else class="text-center">operativo</div>
              </template>
            </Column>
            
<!-- 
            <Column field="expiracionGarantia" header="Expiración Garantía"></Column>
            <Column field="listImagenes" header="Fotos Equipo"></Column>
            <Column field="listAdjuntos" header="Manuales Técnicos"></Column>
            <Column field="listDocumentos" header="Documentos administrativos"></Column>

            <Column field="criticidad" header="Criticidad"></Column>
-->
          </DataTable>
<!-- 
          <qrcode-vue
            value="http://localhost:8080/#/dashboard"
            :size="200"
            level="H"
          />
-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase";
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dialog from "primevue/dialog";
import Column from 'primevue/column';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import QrcodeVue from 'qrcode.vue';
import { getActivosGarantias } from "@/model/functions";
import { getDeviceLastData } from "@/model/functions_powerline-ms";
import { getRTULastData } from "@/model/functions_track-and-trace";
import ModalNuevoEquipo from '../components/Custom/ModalNuevoEquipo.vue';
import { formatDate, daysToDate } from "@/components/utils";

// import { setAgentesGarantias } from "@/model/functions";

export default {
  name: "tables",
  components: {
    DataTable,
    Column,
    InputText,
    QrcodeVue,
    Button,
    ModalNuevoEquipo,
    Dialog
  },

  data() {
    return {
      site: this.site,

      useremail: "",
      totalitems: 0,
      totalEnFalla: 0,
      totalCommissioning: 0,
      total1YearOperation: 0,
      totalCapital: 0,
      showModalCrearEquipo: false,
      filters: {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'itemN': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'ID': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'TAG': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'proyecto': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'nombreEquipo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'descripcion': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'fabricante': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'modelo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'ubicacion': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'fechaPES': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'fechaFinGarantia': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'diasFinGarantia': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'categoria': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
      },

      loading: false,
      fullTable: [],
    };
  },

  methods: {

    formatDate,
    daysToDate,

    select: function (event) {
      var targetId = event.currentTarget.id;
      console.log(targetId); // returns 'foo'
      this.$router.push("/element/" + targetId);
    },

    initTables(user) {
      console.log("<·><><·> initTables() <·><><·>");
      var count = 0;
      var countSpareCommissioning = 0;
      var count1YearOperation = 0;
      var countCapital = 0;

      getActivosGarantias(this.db_ref_inventario, user, (datos) => {
        this.fullTable = datos;

        this.fullTable.forEach(ArrayDB => {
          if (ArrayDB.SpareType == "Commissioning") {
            countSpareCommissioning = countSpareCommissioning + 1;
          } else if (ArrayDB.SpareType == "1 Year Operation") {
            count1YearOperation = count1YearOperation + 1;
          } else if (ArrayDB.SpareType == "Capital") {
            countCapital = countCapital + 1;
          }

          if(ArrayDB.falla) this.totalEnFalla++;

          let fechaFinGarantia = ArrayDB.fechaFinGarantia

          // ArrayDB.diasFinGarantia = Math.ceil((new Date(fechaFinGarantia).getTime() - Date.now())/ (1000 * 60 * 60 * 24));
          ArrayDB.diasFinGarantia = daysToDate(fechaFinGarantia);

          if(this.columnsDataTableElements.timestampLastMessage)
          {
            getDeviceLastData(this.db_ref_stuMessages, ArrayDB.ID, (datos) => { ArrayDB.globalstarData = datos });
          }

          if(this.columnsDataTableElements.localizacion)
          {
            getRTULastData(ArrayDB.ID)
              .then((json_response) => {
                if (json_response == false) {
                  ArrayDB.localizacion = "No encontrado";
                }
                else {
                  ArrayDB.localizacion = json_response.train_data.location.name +
                                    " (PK " + json_response.train_data.location.PK + " - " +
                                    json_response.train_data.location.division + " )";

                  ArrayDB.globalstarData = {};
                  ArrayDB.globalstarData.timestampLastMessage = json_response.timestamp;
                }
              })
              .catch((error) => {
                // logger.error(error);
                ArrayDB.localizacion = "";
              });
          }

          count++;
          this.totalCommissioning = countSpareCommissioning;
          this.total1YearOperation = count1YearOperation;
          this.totalCapital = countCapital;
          this.totalitems = count;
          this.totalEnFalla = this.totalEnFalla + "";
        });
      });
    },
    modalAgregarEquipo(){
      console.log("Entra a crear equipo");
      this.showModalCrearEquipo = true;
    }
  },

  created() {

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.useremail = user.email;
        // console.log("USER SIGNED");
        // console.log("this->", this);
        this.initTables(user);
        // localStorage.setItem("token", response.data.tokenDeAcceso);
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push("/login");
      }
    });

// setAgentesGarantias(this.db_ref_inventario);
  },

  mounted(){},
};
</script>
<style></style>
