<template>
  <div>
    <base-header 
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" 
      :style="(this.headers && this.headers.dashboard)? {
        maxHeight: '150px',
        backgroundImage: this.headers.dashboard.backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: this.headers.dashboard.backgroundPosition
      } : { maxHeight: '150px' }"
    >
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>

    <div class="container-fluid mt--7">
      <!--Charts-->
      <div class="row" >
        <div class="col-xl-6 col-lg-4">
          <div class="row mr-3" >
            <stats-card
              title="Equipos por perder la garantía en 30, 60 y 90 días"
              type="gradient-green"
              v-bind:sub-title="totalitems"
              icon="ni ni-active-40"
              class="mb-4 mb-xl-3 w-100"
            >
              <template v-slot:footer>
                <div id="chart">
                  <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                </div>
              </template>
            </stats-card>
          </div>

          <div class="row mr-3" >
            <tabla-estado-equipos class="mb-4 mb-xl-3 w-100" title="Equipos en falla" :tableData="equiposEnFalla"/>
          </div>
          
        </div>

        <div class="col-xl-6 col-lg-6">
        <!-- <div class="col-xl-10 mb-5 mb-xl-0"> -->
<!--           
          <div class="row" >
            <stats-card
              title="Equipos en garantía vs fuera de garantía"
              type="gradient-red"
              v-bind:sub-title="maxValue"
              icon="ni ni-chart-pie-35"
              class="mb-4 mb-xl-3"
            >
              <template v-slot:footer>
                <div class="row" >
                  <div class="column" >
                    <apexchart type="treemap" width="400" :options="chartOptionsTreeMap" :series="seriesTreeMap"></apexchart>
                  </div>
                </div>
              </template>
            </stats-card>
          </div>
 -->
          <div class="row" >
            <stats-card
              title="Equipos en garantía vs fuera de garantía"
              type="gradient-red"
              v-bind:sub-title="maxValue"
              icon="ni ni-chart-pie-35"
              class="mb-4 mb-xl-3 w-100"
            >
              <template v-slot:footer>
                <div class="row" >
                  <div class="column" >
                    <apexchart width="350" type="donut" :options="chartOptionsPie" :series="seriesPie"></apexchart>
                  </div>
                </div>
              </template>
            </stats-card>
          </div>

          <div v-if="false" class="row" >
            <stats-card
              title="Duración garantías"
              type="gradient-red"
              v-bind:sub-title="maxValue"
              icon="ni ni-chart-pie-35"
              class="mb-4 mb-xl-3 w-100"
            >
              <template v-slot:footer>
                <div class="row" >
                  <div class="column" >
                    <!-- <img src="img/brand/grafico-demo.png" class="navbar-brand-img" style="width:440px" alt="..." /> -->
                    <apexchart type="rangeBar" width="400"  :height="(seriesTime[0].data.length * 20) + 64" :options="chartOptionsTime" :series="seriesTime"></apexchart>
                  </div>
                </div>
              </template>
            </stats-card>
          </div>

          <div class="row" >
            <tabla-estado-equipos class="mb-4 mb-xl-3  w-100" title="Garantias por vencer en menos de 90 días" :tableData="equiposGrantiasPorVencerMenosDe90"/>
          </div>

<!--
          <div class="row" >
            <stats-card
              title="valor máximo"
              type="gradient-red"
              v-bind:sub-title="maxValue"
              icon="ni ni-chart-pie-35"
              class="mb-4 mb-xl-3"
            >
              <template v-slot:footer>
                <div class="row" >
                  <div class="column" >
                    <apexchart width="250"  type="line" :options="chartOptions" :series="series"></apexchart>
                  </div>
                </div>
              </template>
            </stats-card>
          </div>
 -->

          <div class="row" >
            <tabla-estado-equipos class="mb-4 mb-xl-3 w-100" title="Garantias más próximas a vencer" :tableData="equiposGrantiasPorVencer"/>
          </div>

        </div>
      </div>

      <!-- End charts-->

      <!--Tables-->
      <!--
      <div class="row mt-5">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <page-visits-table></page-visits-table>
        </div>
        <div class="col-xl-4">
          <social-traffic-table></social-traffic-table>
        </div>
      </div>-->
      <!--End tables-->
    </div>
  </div>
  
</template>

<script>

import VueApexCharts from "vue3-apexcharts";
//import moment from 'moment';
// Charts
import firebase from 'firebase';
//import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage

//import Chart from "chart.js";

import TablaEstadoEquipos from "./Dashboard/TablaEstadoEquipos";
import { getActivosGarantias, getActivosGarantiasPorVencer } from "@/model/functions";
import { daysToDate } from "@/components/utils";
import moment from "moment";

//Test
function generateDayWiseTimeSeries(baseval, count, yrange) {

  var i = 0;
  var series = [];
  while (i < count) {
    var x = baseval;
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push([x, y]);
    baseval += 86400000;
    i++;
  }
  return series;
}

export default {
  components: {
    TablaEstadoEquipos,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      site: this.site,

      fullTable: [],

      LoadingStatus:"Loading...",
      maxValue:0,

      chartOptions: {
        chart: {
          type:'bar',
          stacked: true,
          id: 'vuechart-example'
        },
        xaxis: {
          // categories: ["DIC 2022", "ENE 2023", "FEB 2023", "MAR 2023", "ABR 2023", "MAY 2023"]
          // categories: ["SEP 2023", "OCT 2023", "NOV 2023", "DIC 2023", "ENE 2024", "FEB 2024"]
          // categories: ["ABR 2024", "MAY 2024", "JUN 2024", "JUL 2024", "AGO 2024", "SEP 2024"]
          // categories: ["MAY 2024", "JUN 2024", "JUL 2024", "AGO 2024", "SEP 2024", "OCT 2024"]
          // categories: ["JUL 2024", "AGO 2024", "SEP 2024", "OCT 2024", "NOV 2024", "DIC 2024"]
          categories: ["SEP 2024", "OCT 2024", "NOV 2024", "DIC 2024", "ENE 2025", "FEB 2025"]
        }
      },

      series: [{
        name: '> 90 días',
        data: []
        // data: [96, 96, 96, 96, 30, 25,]
      },{
        name: '< 90 días',
        data: []
        // data: [10, 10, 10, 10, 60, 55,]
      },{
        name: '< 60 días',
        data: []
        // data: [4, 10, 10, 10, 20, 30,]
      },{
        name: '< 30 días',
        data: []
        // data: [0, 4, 10, 10, 20, 20,]
      }
      ],

      chartOptionsPie: {
        chart: {
        // width: '300px',
        type: 'donut',
        },
        // theme: {
        //   monochrome: {
        //     enabled: true
        //   }
        // },
        dataLabels: {
          enabled: true,
        },
        // labels: ['> 90 días', '< 90 días', '< 60 días', '< 30 días'],
        labels: ['> 90 días', '< 90 días', 'Sin garantía', 'Garantia expirada'],
        
      },

      seriesPie: [96, 10, 4, 0],

      equiposGrantiasPorVencerMenosDe90: [
        // { name: "PR J4 FASE 1 CHILOÉ 1", location: "S/E Ancud", dias: 80, },
        // { name: "PR J4 FASE 2 CHILOÉ 1", location: "S/E Ancud", dias: 75, },
        // { name: "PR J4 FASE 3 CHILOÉ 1", location: "S/E Ancud", dias: 70, },
        // { name: "PR J7 FASE 1 PARGUA 1", location: "S/E Ancud", dias: 60, },
        // { name: "PR J7 FASE 2 PARGUA 1", location: "S/E Ancud", dias: 30, },
      ],

      equiposGrantiasPorVencer: [],

      equiposEnFalla: [],

      seriesTime: [
        {
          data: [
            { x: 'PR J4 FASE 1 CHILOÉ 1', y: [ new Date('2/1/2021').getTime(), new Date('2/1/2023').getTime()] },
            { x: 'PR J4 FASE 2 CHILOÉ 1', y: [ new Date('8/1/2021').getTime(), new Date('6/1/2023').getTime()] },
            { x: 'PR J4 FASE 3 CHILOÉ 1', y: [ new Date('11/1/2021').getTime(), new Date('6/1/2023').getTime()] },
            { x: 'PR J7 FASE 1 PARGUA 1', y: [ new Date('8/1/2021').getTime(), new Date('2/1/2023').getTime()] },
            { x: 'PR J7 FASE 2 PARGUA 1', y: [ new Date('8/1/2021').getTime(), new Date('8/1/2023').getTime()] },
            { x: 'PR J7 FASE 3 PARGUA 1', y: [ new Date('10/1/2021').getTime(), new Date('2/1/2023').getTime()] },
            { x: 'CA J4 FASE 1 CHILOÉ 1', y: [ new Date('12/1/2021').getTime(), new Date('7/1/2023').getTime()] },
            { x: 'CA J4 FASE 3 CHILOÉ 1', y: [ new Date('8/1/2021').getTime(), new Date('8/1/2023').getTime()] },

            // { x: 'CA J4 FASE 1 CHILOÉ 1', y: [ new Date('1/8/2021').getTime(), new Date('1/2/2023').getTime()] },
            // { x: 'CA J4 FASE 1 CHILOÉ 2', y: [ new Date('1/8/2021').getTime(), new Date('1/2/2023').getTime()] },
            // { x: 'CA J4 FASE 1 CHILOÉ 3', y: [ new Date('1/8/2021').getTime(), new Date('1/2/2023').getTime()] },
            // { x: 'CA J4 FASE 1 CHILOÉ 4', y: [ new Date('1/8/2021').getTime(), new Date('1/2/2023').getTime()] },
          ]
        }
      ],

      chartOptionsTime: {
        chart: {
          // height: 850,
          type: 'rangeBar'
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '50%'            
          }
        },
        xaxis: {
          type: 'datetime'
        }
      },

/*
      seriesTreeMap: [
        {
          name: 'Sin verificacion',
          data: [
            {
              x: 'No verificados garantia expirada',
              y: 21
            },
            {
              x: 'No verificados garantia por expirar',
              y: 34
            },
            {
              x: 'No verificados garantia vigente',
              y: 60
            },
          ]
        },
        {
          name: 'Verificados',
          data: [
            {
              x: 'Verificados garantia expirada',
              y: 10
            },
            {
              x: 'Verificados garantia por expirar',
              y: 22
            },
            {
              x: 'Verificados garantia vigente',
              y: 60
            },
            // {
            //   x: 'ABCD',
            //   y: 10
            // },
            // {
            //   x: 'DEFG',
            //   y: 20
            // },
            // {
            //   x: 'WXYZ',
            //   y: 51
            // },
            // {
            //   x: 'PQR',
            //   y: 30
            // },
            // {
            //   x: 'MNO',
            //   y: 20
            // },
            // {
            //   x: 'CDE',
            //   y: 30
            // }
          ]
        }
      ],
*/

/*
      chartOptionsTreeMap: {
        legend: {
          show: false
        },
        chart: {
          // height: 350,
          type: 'treemap'
        },
        // title: {
        //   text: 'Multi-dimensional Treemap',
        //   align: 'center'
        // }
      },
*/
    };
  },

  methods: {

    daysToDate,

    initTables() {
      console.log("<·><><·> initTables() <·><><·>");

      //ToDo: guardar todas las fechas como int para permitir efectivamente ordenar por fecha:
      getActivosGarantiasPorVencer(this.db_ref_inventario, 10, (datos) => {
        console.log("datos (equiposGrantiasPorVencer) -> ", datos);

        this.equiposGrantiasPorVencer = [];
        datos.forEach(ArrayDB => {
          if (ArrayDB.fechaFinGarantia)
          {
            const diasGrantiaRestantes = daysToDate(ArrayDB.fechaFinGarantia);
            this.equiposGrantiasPorVencer.push(
              {
                name: ArrayDB.nombreEquipo,
                location: ArrayDB.ubicacion,
                dias: diasGrantiaRestantes,
              }
            );
          }
        });
      });

      getActivosGarantias(this.db_ref_inventario, null, (datos) => {
        console.log("datos -> ", datos);

        let equiposEnFalla = [];
        let equiposGrantiasPorVencerMenosDe90 = [];

        let equiposSinGarantia = 0;
        let garantiaExpirada = 0;

        let garantiaMasDe90 = [];
        let garantiaMenosDe90 = [];
        let garantiaMenosDe60 = [];
        let garantiaMenosDe30 = [];

        let numPeriodos = 6;
        for (let i = 0; i < numPeriodos; i++) {
          garantiaMasDe90.push(0);
          garantiaMenosDe90.push(0);
          garantiaMenosDe60.push(0);
          garantiaMenosDe30.push(0);
        }

        let periodos = [];
        let dateNow = new Date(); //new Date("09/31/2024");

        for (let i = 0; i < numPeriodos; i++) {
          periodos.push(new Date(dateNow.getFullYear(), dateNow.getMonth() + i, 1));
        }

        datos.forEach(ArrayDB => {

          const diasGrantiaRestantes = [ daysToDate(ArrayDB.fechaFinGarantia) ];
          for (let i = 1; i < numPeriodos; i++) {
            diasGrantiaRestantes.push(daysToDate(ArrayDB.fechaFinGarantia) - daysToDate(periodos[i]));
          }

          //Equipos en falla
          if(ArrayDB.falla)
          {
            equiposEnFalla.push(
              {
                name: ArrayDB.nombreEquipo,
                location: ArrayDB.ubicacion,
                dias: diasGrantiaRestantes[0],
              }
            );
          }

          //Equipos Garantias próximas a vencer
          if(diasGrantiaRestantes[0] < 90)
          {
            equiposGrantiasPorVencerMenosDe90.push(
              {
                name: ArrayDB.nombreEquipo,
                location: ArrayDB.ubicacion,
                dias: diasGrantiaRestantes[0],
              }
            );
          }

          for (let i = 0; i < diasGrantiaRestantes.length; i++) {
            //Equipos en garantía vs fuera de garantía
            if (diasGrantiaRestantes[i] < 0) //garantía expirada
            {
              if(i == 0)
              {
                garantiaExpirada += 1;
              }
            }
            else if (diasGrantiaRestantes[i] >= 90) {
              garantiaMasDe90[i] += 1;
            }
            else if (diasGrantiaRestantes[i] < 90 && diasGrantiaRestantes[i] >= 60) {
              garantiaMenosDe90[i] += 1;
            }            
            else if (diasGrantiaRestantes[i] < 60 && diasGrantiaRestantes[i] >= 30) {
              garantiaMenosDe60[i] += 1;
            }
            else if (diasGrantiaRestantes[i] < 30) {
              garantiaMenosDe30[i] += 1;
            }
          }
        });

        this.equiposEnFalla = equiposEnFalla;
        this.equiposGrantiasPorVencerMenosDe90 = equiposGrantiasPorVencerMenosDe90;
        this.seriesPie = [garantiaMasDe90[0], (garantiaMenosDe90[0] + garantiaMenosDe60[0] + garantiaMenosDe30[0]), equiposSinGarantia, garantiaExpirada];

        this.series = [{
            name: '> 90 días',
            data: garantiaMasDe90
          },{
            name: '< 90 días',
            data: garantiaMenosDe90
          },{
            name: '< 60 días',
            data: garantiaMenosDe60
          },{
            name: '< 30 días',
            data: garantiaMenosDe30
          }
        ];


        // ----- <apexchart type="rangeBar"> ----- //
        /*
        let data = [];
        let i = 0;
        datos.forEach(ArrayDB => {
          // console.log("ArrayDB.ID: %s", ArrayDB.ID);
          i++;
          if(i < 20)
          {
            data.push(
              {
                x: ArrayDB.ID,
                y: [
                  new Date('8/1/2021').getTime(),
                  new Date('1/23/2023').getTime()
                ]
              }            
            );
          }
        });
        // this.chartOptionsTime.chart = {
        //   height: 10 * i,
        //   type: 'rangeBar'
        // };

        console.log("this.seriesTime: -> ", this.seriesTime[0].data);
        this.seriesTime = [{ data: data }];
        */
        // ----- <apexchart type="rangeBar"> ----- //

      });
    },
  },

  mounted() { //when the website mounts...starts

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.username = user.email;
        console.log("USER SIGNED IN Dashboard");
        this.initTables();
      }
      else
      {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push("/login");
      }
    });
  },

  created(){},
};
</script>
<style></style>
