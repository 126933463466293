/*!

=========================================================
* Vue Argon Dashboard - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import Vue from "vue";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
// import "element-plus/lib/theme-chalk/index.css";
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage

//import Vue from 'vue';
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ToastService from "primevue/toastservice";
import VueApexCharts from "vue3-apexcharts";

const appInstance = createApp(App);

const site = "BHP";
// const site = "Transelec";
// const site = "Powerline MS";
// const site = "Aquanima-Codelco";
// const site = "Celplan-Codelco";
// const site = "Demo";
// const site = "Fepasa";
appInstance.config.globalProperties.site = site;

const headersDefaultCodelco = { 
    dashboard: {
        backgroundImage: 'url(img/layout/codelco/chuqui.jpg)', backgroundPosition: 'center center'
    },
    listadoEquipos: { 
        backgroundImage: 'url(img/layout/codelco/camiones2.jpg)', backgroundPosition: 'center center'
    },
    bandejaEntrada: {
        backgroundImage: 'url(img/layout/codelco/el_teniente_tunel.jpg)', backgroundPosition: 'center center'
    },
    detalleEquipo: {
        backgroundImage: 'url(img/layout/codelco/tunel_codelco.png)', backgroundPosition: 'center center'
    },
    usuarios: {
        backgroundImage: 'url(img/layout/codelco/chuqui_users.png)', backgroundPosition: 'center center'
    },
};

switch (site) {
    case "Transelec":
        /*  Transelec */
        /* % firebase deploy --only hosting:aquanima */

        appInstance.config.globalProperties.urlBase = "http://trelec-ec4fb.web.app";

        appInstance.config.globalProperties.clientName = "Transelec"; 
        appInstance.config.globalProperties.loginPage = {
            logo: 'img/brand/transelec/logo-transelec-blue-transp.png'
        };
        
        appInstance.config.globalProperties.sideBarImage = "img/brand/transelec/transelec_logo.png";
        appInstance.config.globalProperties.clientPartner = "";
        appInstance.config.globalProperties.clientPartnerURL = "";

        appInstance.config.globalProperties.headers = { 
            dashboard: {
                backgroundImage: 'url(img/layout/transelec/power-lines.jpg)', backgroundPosition: 'center top'
            },
            listadoEquipos: { 
                backgroundImage: 'url(img/layout/transelec/sunset.jpg)', backgroundPosition: 'center center'
            },
            bandejaEntrada: {
                backgroundImage: 'url(img/layout/transelec/industry.jpg)', backgroundPosition: 'center center'
            },
            detalleEquipo: {
                backgroundImage: 'url(img/layout/transelec/aisladores.jpg)', backgroundPosition: 'center top'
            },
            usuarios: {
                backgroundImage: 'url(img/layout/transelec/altura.jpg)', backgroundPosition: 'center bottom'
            },
        };

        appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes"; 
        appInstance.config.globalProperties.db_ref_inventario = "inventario/Central"; 
        appInstance.config.globalProperties.db_ref_base_inventario = "inventario"; //no utilizada
        appInstance.config.globalProperties.db_ref_users = "usuarios/Transelec";         

        appInstance.config.globalProperties.columnsDataTableElements = {
            default: false,
            ID: false,
            TAG: true,
            numeroSerie: false,
            nombreEquipo: true,
            descripcion: true,
            contrato: true,
            idContrato: false,
            proyecto: true,
            fabricante: false,
            proveedor: true,
            modelo: false,
            ubicacion: true,
            areaResponsable: true,
            comentarios: false,
            anoFabricacion: true,
            fechaPES: false,
            mesesGarantia: true,
            fechaFinGarantia: true,
            diasFinGarantia: true,
            categoria: false,
            falla: true,
        };        
        appInstance.config.globalProperties.sectionsElementView = {
            estadoGarantia: true,
            detallesEquipo: false,
            informacionAdicional: false,
            historial: false,
            fichaProveedor: true,
            fichaTecnica: false,
        }
        break;

    case "BHP":
        /*  BHP */
        /* % firebase deploy --only hosting:bhp */

        appInstance.config.globalProperties.urlBase = "https://e-garant-bhp.pcvuelat.com";
        // appInstance.config.globalProperties.urlBase = "https://e-garant-bhp.web.app";

        appInstance.config.globalProperties.clientName = "BHP";

        appInstance.config.globalProperties.loginPage = {
            logo: 'img/brand/bhp/logo_white.png',
            primaryColor: "#CD6026",
            primaryAltColor: "#CD6026",
            secondaryColor: "whitesmoke",
            secondaryAltColor: "silver",
        };
        
        appInstance.config.globalProperties.sideBarImage = "img/brand/bhp/logo_sidebar.jpeg";
        appInstance.config.globalProperties.clientPartner = "";
        appInstance.config.globalProperties.clientPartnerURL = "";
        
        appInstance.config.globalProperties.headers = headersDefaultCodelco;

        appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes2/Escondida";
        // appInstance.config.globalProperties.db_ref_inventario = "inventario/BHP/Escondida";
        appInstance.config.globalProperties.db_ref_inventario = "inventario/BHP/Escondida/LSTSWP02";
        appInstance.config.globalProperties.db_ref_base_inventario = "inventario/BHP/Escondida";
        appInstance.config.globalProperties.db_ref_users = "usuarios2/BHP";
 
        appInstance.config.globalProperties.columnsDataTableElements = {
            default: false,
            ID: false,
            TAG: true,
            numeroSerie: false,
            nombreEquipo: true,
            descripcion: false,
            contrato: true,
            idContrato: false,
            proyecto: true,
            fabricante: false,
            proveedor: false,
            modelo: false,
            ubicacion: true,
            areaResponsable: false,
            comentarios: false,
            anoFabricacion: false,
            fechaPES: false,
            mesesGarantia: false,
            fechaFinGarantia: true,
            diasFinGarantia: true,
            categoria: false,
            falla: true,
        };        
        appInstance.config.globalProperties.sectionsElementView = {
            estadoGarantia: true,
            detallesEquipo: true,
            informacionAdicional: true,
            historial: true,
            fichaProveedor: true,
            fichaTecnica: false,
        }        
        break;

    case "Demo":
        /*  Demo */
        /* % firebase deploy --only hosting:demo-clientes */

        appInstance.config.globalProperties.urlBase = "https://e-garant-clientes.web.app";

        appInstance.config.globalProperties.clientName = "BHP";

        appInstance.config.globalProperties.loginPage = {
            logo: 'img/brand/bhp/logo_white.png',
            primaryColor: "#CD6026",
            primaryAltColor: "#CD6026",
            secondaryColor: "whitesmoke",
            secondaryAltColor: "silver",
        };
        
        appInstance.config.globalProperties.sideBarImage = "img/brand/bhp/logo_sidebar.jpeg";
        appInstance.config.globalProperties.clientPartner = "";
        appInstance.config.globalProperties.clientPartnerURL = "";
        
        appInstance.config.globalProperties.headers = headersDefaultCodelco;

        appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes2/Spence";
        appInstance.config.globalProperties.db_ref_inventario = "inventario/Spence";
        appInstance.config.globalProperties.db_ref_base_inventario = "inventario"; //no utilizada
        appInstance.config.globalProperties.db_ref_users = "usuarios2/BHP";
 
        appInstance.config.globalProperties.columnsDataTableElements = {
            default: false,
            ID: false,
            TAG: true,
            numeroSerie: false,
            nombreEquipo: true,
            descripcion: false,
            contrato: true,
            idContrato: false,
            proyecto: true,
            fabricante: false,
            proveedor: false,
            modelo: false,
            ubicacion: true,
            areaResponsable: false,
            comentarios: false,
            anoFabricacion: false,
            fechaPES: false,
            mesesGarantia: false,
            fechaFinGarantia: true,
            diasFinGarantia: true,
            categoria: false,
            falla: true,
        };        
        appInstance.config.globalProperties.sectionsElementView = {
            estadoGarantia: true,
            detallesEquipo: true,
            informacionAdicional: true,
            historial: true,
            fichaProveedor: true,
            fichaTecnica: false,
        }
        break;

    case "Aquanima-Codelco":
        /*  Aquanima - Codelco */ 
        /*  10/10/2023 16:45: git checkout f26bd911ee5e562ab4f72349a940fe9b903509d5 */
        /* % firebase deploy --only hosting:aquanima */

        appInstance.config.globalProperties.urlBase = "https://e-garant.pcvuelat.com";
        // appInstance.config.globalProperties.urlBase = "https://transelec.pcvuelat.com";
        // appInstance.config.globalProperties.urlBase = "http://trelec-ec4fb.web.app";

        appInstance.config.globalProperties.clientName = "Codelco";

        appInstance.config.globalProperties.loginPage = {
            logo: 'img/brand/codelco/logo_cyan.jpg',
            primaryColor: "#0098AA", 
            primaryAltColor: "#0072B1",
            secondaryColor: "#172B4D",
            secondaryAltColor: "#172B4D",
        };

        appInstance.config.globalProperties.sideBarImage = "img/brand/codelco/logo_sidebar.png";
        appInstance.config.globalProperties.clientPartner = "Aquanima";
        appInstance.config.globalProperties.clientPartnerURL = "https://www.aquanima.com";

        appInstance.config.globalProperties.headers = headersDefaultCodelco;

        appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes2/Teniente"; 
        appInstance.config.globalProperties.db_ref_inventario = "inventario/Teniente"; 
        appInstance.config.globalProperties.db_ref_base_inventario = "inventario"; //no utilizada
        appInstance.config.globalProperties.db_ref_users = "usuarios2/Aquanima"; 

        appInstance.config.globalProperties.columnsDataTableElements = {
            default: false,
            ID: false,
            TAG: true,
            numeroSerie: false,
            nombreEquipo: true,
            descripcion: false,
            contrato: true,
            idContrato: false,
            proyecto: true,
            fabricante: false,
            proveedor: false,
            modelo: false,
            ubicacion: true,
            areaResponsable: false,
            comentarios: false,
            anoFabricacion: false,
            fechaPES: false,
            mesesGarantia: false,
            fechaFinGarantia: true,
            diasFinGarantia: true,
            categoria: false,
            falla: true,
        };        
        appInstance.config.globalProperties.sectionsElementView = {
            estadoGarantia: true,
            detallesEquipo: false,
            informacionAdicional: false,
            historial: false,
            fichaProveedor: true,
            fichaTecnica: false,
        }  
        break;

    case "Celplan-Codelco":
        /*  Aquanima - Codelco */ /*  ==>  */ /*  Celplan - Codelco */
        /* % firebase deploy --only hosting:aquanima */

        appInstance.config.globalProperties.urlBase = "https://e-garant.pcvuelat.com";
        // appInstance.config.globalProperties.urlBase = "https://transelec.pcvuelat.com";
        // appInstance.config.globalProperties.urlBase = "http://trelec-ec4fb.web.app";

        appInstance.config.globalProperties.clientName = "Codelco";

        appInstance.config.globalProperties.loginPage = {
            logo: 'img/brand/codelco/logo_cyan.jpg',
            primaryColor: "#0098AA", 
            primaryAltColor: "#0072B1",
            secondaryColor: "#172B4D",
            secondaryAltColor: "#172B4D",
        };

        appInstance.config.globalProperties.sideBarImage = "img/brand/codelco/logo_sidebar.png";
        appInstance.config.globalProperties.clientPartner = "";
        appInstance.config.globalProperties.clientPartnerURL = "";

        appInstance.config.globalProperties.headers = headersDefaultCodelco;

        appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes2/Teniente"; 
        appInstance.config.globalProperties.db_ref_inventario = "inventario/Celplan"; 
        appInstance.config.globalProperties.db_ref_base_inventario = "inventario"; //no utilizada
        appInstance.config.globalProperties.db_ref_users = "usuarios2/Aquanima"; 

        appInstance.config.globalProperties.columnsDataTableElements = {
            default: false,
            ID: false,
            TAG: true,
            numeroSerie: false,
            nombreEquipo: true,
            descripcion: false,
            contrato: true,
            idContrato: false,
            proyecto: true,
            fabricante: false,
            proveedor: false,
            modelo: false,
            ubicacion: true,
            areaResponsable: false,
            comentarios: false,
            anoFabricacion: false,
            fechaPES: false,
            mesesGarantia: false,
            fechaFinGarantia: true,
            diasFinGarantia: true,
            categoria: false,
            falla: true,
        };        
        appInstance.config.globalProperties.sectionsElementView = {
            estadoGarantia: true,
            detallesEquipo: false,
            informacionAdicional: false,
            historial: false,
            fichaProveedor: true,
            fichaTecnica: false,
        }        
        break;

    case "Powerline MS":
        /*  Powerline MS */
        /*  % firebase use powerline-ms-hosting */
        /*  % firebase deploy --only hosting:powerline-ms */

        appInstance.config.globalProperties.urlBase = "https://powerline-ms.web.app";

        appInstance.config.globalProperties.clientName = "BHP";

        appInstance.config.globalProperties.loginPage = {
            logo: 'img/brand/bhp/logo_white.png',
            primaryColor: "#CD6026",
            primaryAltColor: "#CD6026",
            secondaryColor: "whitesmoke",
            secondaryAltColor: "silver",
        };
        
        appInstance.config.globalProperties.sideBarImage = "img/brand/bhp/logo_sidebar.jpeg";
        appInstance.config.globalProperties.clientPartner = "";
        appInstance.config.globalProperties.clientPartnerURL = "";

        appInstance.config.globalProperties.headers = { 
            dashboard: {
                backgroundImage: 'url(img/layout/transelec/power-lines.jpg)', backgroundPosition: 'center top' //+Transelec
            },
            listadoEquipos: { 
                backgroundImage: 'url(img/layout/transelec/sunset.jpg)', backgroundPosition: 'center center' //+Transelec
            },
            bandejaEntrada: {
                backgroundImage: 'url(img/layout/transelec/industry.jpg)', backgroundPosition: 'center center' //+Transelec
            },
            detalleEquipo: {
                backgroundImage: 'url(img/layout/transelec/aisladores.jpg)', backgroundPosition: 'center top' //+Transelec
            },
            usuarios: {
                backgroundImage: 'url(img/layout/transelec/altura.jpg)', backgroundPosition: 'center bottom' //+Transelec
            },
        };
        
        // appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes/BHP";
        appInstance.config.globalProperties.db_ref_inventario = "inventario/BHP";
        appInstance.config.globalProperties.db_ref_base_inventario = "inventario"; //no utilizada
        appInstance.config.globalProperties.db_ref_users = "usuarios";

        appInstance.config.globalProperties.db_ref_stuMessages = "stuMessages";
        appInstance.config.globalProperties.timeAlerts = {"error": (48 * 3600 * 1000), "warn": (4 * 3600 * 1000), "success": (80 * 60 * 1000)};

        appInstance.config.globalProperties.columnsDataTableElements = {
            default: false,
            ID: true,
            TAG: true,
            numeroSerie: false,
            nombreEquipo: true,
            descripcion: false,
            contrato: false,
            idContrato: false,
            proyecto: false,
            fabricante: false,
            proveedor: true,
            modelo: false,
            ubicacion: true,
            areaResponsable: false,
            comentarios: false,
            anoFabricacion: false,
            fechaPES: false,
            mesesGarantia: false,
            fechaFinGarantia: false,
            diasFinGarantia: false,
            categoria: false,
            falla: false,

            //Datos especificos:
            localizacion: false,
            timestampLastMessage: true,
        };
        appInstance.config.globalProperties.sectionsElementView = {
            estadoGarantia: false,
            detallesEquipo: true,
            infoOnlineEquipo: true,
            informacionAdicional: true,
            historial: false,
            fichaProveedor: true,
            fichaTecnica: false,
        }

        //Google Maps 
        appInstance.config.globalProperties.googleMapsAPIKey = "AIzaSyBpBkHOx-N9bREoBF3tsiUBmsW-WcurrlM"
        appInstance.config.globalProperties.googleMapsLoader = null

        break;

    case "Fepasa":
        /*  Fepasa Track And Trace */

        appInstance.config.globalProperties.urlBase = "https://powerline-ms.web.app";

        appInstance.config.globalProperties.clientName = "FEPASA";

        appInstance.config.globalProperties.loginPage = {
            logo: 'img/brand/fepasa/logo_white.png',
            primaryColor: "#172B4D", //"#0098AA", 
            primaryAltColor: "red", // "#0072B1",
            secondaryColor: "whitesmoke",
            secondaryAltColor: "whitesmoke",
        };

        appInstance.config.globalProperties.sideBarImage = "img/brand/fepasa/logo_sidebar.jpeg";
        appInstance.config.globalProperties.clientPartner = "";
        appInstance.config.globalProperties.clientPartnerURL = "";

        appInstance.config.globalProperties.headers = { 
            dashboard: {
                backgroundImage: 'url(img/layout/fepasa/servicios2.jpg)',
                backgroundPosition: 'center center'
            },
            listadoEquipos: {
                backgroundImage: 'url(img/layout/fepasa/servicios2.jpg)',
                backgroundPosition: 'top center'
            },
            bandejaEntrada: {
                backgroundImage: 'url(img/layout/fepasa/sostenibilidad-home.jpg)',
                backgroundPosition: 'center center'
            },
            detalleEquipo: {
                backgroundImage: 'url(img/layout/fepasa/nosotros.jpg)',
                backgroundPosition: 'top center'
            },
            usuarios: {
                backgroundImage: 'url(img/layout/fepasa/nosotros.jpg)',
                backgroundPosition: 'center center'
            },
        };

        // appInstance.config.globalProperties.db_ref_solicitudes = "solicitudes/BHP";
        appInstance.config.globalProperties.db_ref_inventario = "e-garant/inventario/FEPASA";
        appInstance.config.globalProperties.db_ref_base_inventario = "e-garant/inventario"; //no utilizada
        appInstance.config.globalProperties.db_ref_users = "e-garant/usuarios";

        appInstance.config.globalProperties.db_ref_stuMessages = "e-garant/stuMessages";
        appInstance.config.globalProperties.timeAlerts = { "error": (48 * 3600 * 1000), "warn": (4 * 3600 * 1000), "success": (80 * 60 * 1000) };

        appInstance.config.globalProperties.columnsDataTableElements = {
            default: false,
            ID: true,
            TAG: true,
            numeroSerie: false,
            nombreEquipo: true,
            descripcion: false,
            contrato: false,
            idContrato: false,
            proyecto: false,
            fabricante: false,
            proveedor: false,
            modelo: false,
            ubicacion: false,
            areaResponsable: false,
            comentarios: false,
            anoFabricacion: false,
            fechaPES: false,
            mesesGarantia: false,
            fechaFinGarantia: false,
            diasFinGarantia: false,
            categoria: false,
            falla: false,

            //Datos especificos:
            localizacion: true,
            timestampLastMessage: true,
        };
        appInstance.config.globalProperties.sectionsElementView = {
            estadoGarantia: false,
            detallesEquipo: true,
            infoOnlineEquipo: false,
            informacionAdicional: false,
            historial: false,
            fichaProveedor: false,
            fichaTecnica: false,
        }

        //Google Maps 
        appInstance.config.globalProperties.googleMapsAPIKey = "AIzaSyBpBkHOx-N9bREoBF3tsiUBmsW-WcurrlM"
        appInstance.config.globalProperties.googleMapsLoader = null

        break;
        
    default:
        break;
}

// appInstance.config.globalProperties.backgroundImages = {
//     dashboard: 'img/brand/codelco/chuqui.jpg',
//     element: 'img/brand/codelco/tunel_codelco.png',
//     inboxSolicitudes: 'img/brand/codelco/el_teniente_tunel.jpg',
//     tables: 'img/brand/codelco/camiones2.jpg',
//     usuarios: 'img/brand/codelco/chuqui_users.png',
// };

appInstance.config.globalProperties.letterTemplate = {
    BHP: '' //clientName
        + '<p class="ql-align-right"><img src="img/assets/bhp/headerCarta.png" alt="" height="137" ></p>'
        + '<p class="ql-align-justify"><span style="background-color: transparent; color: rgb(255, 0, 0);">XXXXXXX-X</span><span style="background-color: transparent; color: rgb(0, 0, 0);">-CR-T-00</span><span style="background-color: transparent; color: rgb(255, 0, 0);">XX&nbsp;</span></p><p>'
        + '<span style="background-color: transparent; color: rgb(0, 0, 0);">Santiago, </span>#date#</p>'
        + '<p class="ql-align-right">Requiere Respuesta:&nbsp;Si: <u>X</u> / No: __ </p>'
        + '<p class="ql-align-justify">Señores</p><p>#proveedor#</p><p><br></p>'
        + '<p><strong>Atención</strong>: 	Sr. XXX XXX</p><p>'
        + '<strong>Contrato</strong>:	#idContrato#</p>'
        + '<p><strong>Referencia</strong>:	“XXX”</p>'
        + '<p><strong>Asunto</strong>:	Notificación de activación de garantía de equipo</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Estimados señores:</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Por medio de la presente se le notifica a #proveedor_1# de que el equipo #nombreEquipo#, suministro comprado mediante contrato #idContrato_1#, ha presentado la siguiente falla: #reporteFalla#, toda vez que el equipo se encuentra con la garantía en periodo vigente.&nbsp;</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Solicitamos gestionar la solución propuesta por ustedes y enviarla al Ingeniero de Contratos #ingenieroContrato# al correo #mailIngenieroContrato# en un plazo máximo de 5 días hábiles.</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Sin otro particular, saluda atentamente a usted,</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><strong></strong></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Gerente de Proyecto</p>'
        + '<p class="ql-align-justify"><strong>Contrato #idContrato_2#</strong></p><p><br></p>'
        // + '<p><img src="img/footerCarta.png" height="120" width="754.3727264404297"></p>';
        + '<p></p>',

    Codelco: '' //clientName
        // + '<p class="ql-align-right"><img src="img/headerCarta.png" alt="" height="137" width="651"></p>'
        + '<p class="ql-align-justify"><span style="background-color: transparent; color: rgb(255, 0, 0);">XXXXXXX-X</span><span style="background-color: transparent; color: rgb(0, 0, 0);">-CR-T-00</span><span style="background-color: transparent; color: rgb(255, 0, 0);">XX&nbsp;</span></p><p>'
        + '<span style="background-color: transparent; color: rgb(0, 0, 0);">Santiago, </span>#date#</p>'
        + '<p class="ql-align-right">Requiere Respuesta:&nbsp;Si: <u>X</u> / No: __ </p>'
        + '<p class="ql-align-justify">Señores</p><p>#proveedor#</p><p><br></p>'
        + '<p><strong>Atención</strong>: 	Sr. XXX XXX</p><p>'
        + '<strong>Contrato</strong>:	#idContrato#</p>'
        + '<p><strong>Referencia</strong>:	“XXX”</p>'
        + '<p><strong>Asunto</strong>:	Notificación de activación de garantía de equipo</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Estimados señores:</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Por medio de la presente se le notifica a #proveedor_1# de que el equipo #nombreEquipo#, suministro comprado mediante contrato #idContrato_1#, ha presentado la siguiente falla: #reporteFalla#, toda vez que el equipo se encuentra con la garantía en periodo vigente.&nbsp;</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Solicitamos gestionar la solución propuesta por ustedes y enviarla al Ingeniero de Contratos #ingenieroContrato# al correo #mailIngenieroContrato# en un plazo máximo de 5 días hábiles.</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Sin otro particular, saluda atentamente a usted,</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><strong></strong></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Gerente de Proyecto</p>'
        + '<p class="ql-align-justify"><strong>Contrato #idContrato_2#</strong></p><p><br></p>'
        // + '<p><img src="img/footerCarta.png" height="120" width="754.3727264404297"></p>';
        + '<p></p>',

    Transelec: '' //clientName
        + '<p class="ql-align-right"><img src="img/assets/transelec/headerCarta.png" alt="TRANSELEC HOJA CARTA (06 10 2014)-03.png" height="137" width="651"></p>'
        + '<p class="ql-align-justify"><span style="background-color: transparent; color: rgb(255, 0, 0);">XXXXXXX-X</span><span style="background-color: transparent; color: rgb(0, 0, 0);">-CR-T-00</span><span style="background-color: transparent; color: rgb(255, 0, 0);">XX&nbsp;</span></p><p>'
        + '<span style="background-color: transparent; color: rgb(0, 0, 0);">Santiago, </span>#date#</p>'
        + '<p class="ql-align-right">Requiere Respuesta:&nbsp;Si: <u>X</u> / No: __ </p>'
        + '<p class="ql-align-justify">Señores</p><p>#proveedor#</p><p><br></p>'
        + '<p><strong>Atención</strong>: 	Sr. XXX XXX</p><p>'
        + '<strong>Contrato</strong>:	#idContrato#</p>'
        + '<p><strong>Referencia</strong>:	“XXX”</p>'
        + '<p><strong>Asunto</strong>:	Notificación de activación de garantía de equipo</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Estimados señores:</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Por medio de la presente se le notifica a #proveedor_1# de que el equipo #nombreEquipo#, suministro comprado mediante contrato #idContrato_1#, ha presentado la siguiente falla #reporteFalla#, toda vez que el equipo se encuentra con la garantía en periodo vigente.&nbsp;</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Solicitamos gestionar la solución propuesta por ustedes y enviarla al Ingeniero de Contratos #ingenieroContrato# al correo #mailIngenieroContrato# en un plazo máximo de 5 días hábiles.</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Sin otro particular, saluda atentamente a usted,</p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><strong>TRANSELEC S.A.</strong></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify"><br></p>'
        + '<p class="ql-align-justify">Gerente de Proyecto</p>'
        + '<p class="ql-align-justify"><strong>Contrato #idContrato_2#</strong></p><p><br></p>'
        + '<p><img src="img/assets/transelec/footerCarta.png" height="120" width="754.3727264404297"></p>',

}

appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(PrimeVue);
appInstance.use(DataTable);
appInstance.use(InputText);
appInstance.use(Column);
appInstance.use(Button);
appInstance.use(ToastService);
appInstance.use(VueApexCharts);
appInstance.mount("#app");

var firebaseConfig = {}

if (site == "Powerline MS") { /*  Powerline MS */
    
    firebaseConfig = {
        apiKey: "AIzaSyA7ELYA-XO0a6pdiROKM6GOOhgkovxknI4",
        authDomain: "powerline-ms.firebaseapp.com",
        databaseURL: "https://powerline-ms-default-rtdb.firebaseio.com",
        projectId: "powerline-ms",
        storageBucket: "powerline-ms.appspot.com",
        messagingSenderId: "403776805284",
        appId: "1:403776805284:web:160bde6251cea6c6130931",
        measurementId: "G-79HREG4P60"
    };
}
else if (site == "Fepasa") { /*  Fepasa Track And Trace */

    firebaseConfig = {
        apiKey: "AIzaSyBuLAnGfIwkd5tuJCBeveKd8uqTytp-AwI",
        authDomain: "fepasa-track-and-trace.firebaseapp.com",
        databaseURL: "https://fepasa-track-and-trace-default-rtdb.firebaseio.com",
        projectId: "fepasa-track-and-trace",
        storageBucket: "fepasa-track-and-trace.appspot.com",
        messagingSenderId: "313737155082",
        appId: "1:313737155082:web:298a57f8971d7a652cc5f3",
        measurementId: "G-NTS9YPG176"
      };
}
else { /*  Trelec (Firebase) */
    
    firebaseConfig = {
        apiKey: "AIzaSyAcqYU4VUa3UT0zuKzSjk4oazwotrmXp7c",
        authDomain: "trelec-ec4fb.firebaseapp.com",
        databaseURL: "https://trelec-ec4fb-default-rtdb.firebaseio.com",
        projectId: "trelec-ec4fb",
        storageBucket: "trelec-ec4fb.appspot.com",
        messagingSenderId: "393977052780",
        appId: "1:393977052780:web:3c03ad67ce0a5a0873e249",
        measurementId: "G-XMH8W5MPNQ"
    };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//Vue.config.productionTip = false;

/* new Vue({
    router,
    render: h => h(App),
}).$mount('#app'); */